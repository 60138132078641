import React, { useState, useEffect } from 'react'
import Pagination from '../../Pagination';
const CsNormalClasses = () => {
    let itemsPerPage = 5;
    const [classDetail, setClassDetail] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(classDetail?.length / itemsPerPage);
const classes=[
    {
        title: "Y3 Assessment English",
        question: "40",
        time: "50min"
    },
     {
        title: "Y3 Assessment English",
        question: "40",
        time: "50min"
    },
     {
        title: "Y3 Assessment English",
        question: "40",
        time: "50min"
    }, 
    {
        title: "Y3 Assessment English",
        question: "40",
        time: "50min"
    }, 
    {
        title: "Y3 Assessment English",
        question: "40",
        time: "50min"
    }
]
  
    useEffect(() => {
        async function get_data() {
            // const grade = await get_grade();
            // // const testlist = await assemenent_test_list(currentPage);
            // setGrades(grade?.data);
            setClassDetail(classes);
        }
        get_data();
    }, []);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = classDetail?.slice(startIndex, endIndex);



  return (
    <div class="row">
    <div class="col-12">
        <div class="grade_content">
            <h2>My Attendance in August</h2>
            <div class="study_room date-slot activity-sec">
                <ul>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">1</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">2</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">3</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">4</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">5</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">6</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">7</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">8</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">9</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">10</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">11</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">12</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">13</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">14</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">15</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">16</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">17</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">18</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">19</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">20</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">21</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">22</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">23</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">24</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">25</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">26</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">27</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">28</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">29</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">30</a>
                    </li>
                    <li class="list-group-item">
                        <input type="radio" id="male" name="Time" />
                        <a href="javascript:void(0);" class="">31</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="table_head study_head">
            <h2 class="">CS Education 7-Step Learning Management System</h2>
        </div>
        <div class="table-responsive tbl_bg study_tbl">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Step 1</th>
                        <th scope="col">Step 2</th>
                        <th scope="col">Step 3</th>
                        <th scope="col">Step 4</th>
                        <th scope="col">Step 5</th>
                        <th scope="col">Step 6</th>
                        <th scope="col">Step 7</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td scope="row">
                            Assessment<br />
                            Tests
                        </td>
                        <td>
                            Class Review<br />
                            (Lecture)
                        </td>
                        <td>
                            Self-Directed<br />
                            Study
                        </td>
                        <td>Homework</td>
                        <td>e-Tutoring</td>
                        <td>Consulting</td>
                        <td>
                            Summative<br />
                            Assessment<br />
                            (CLASS TEST)
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-12">
        <div class="table-responsive tbl_bg ">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Title</th>
                        <th scope="col">No. of Question</th>
                        <th scope="col">Time</th>
                        <th scope="col">Take Test</th>
                        <th scope="col">My Result</th>
                        <th scope="col">
                            Review Incorrect<br />
                            Answers
                        </th>
                    </tr>
                </thead>
                <tbody>
                          {currentData && currentData?.map((elem)=>{
                            return(
                                <tr>
                                    <td scope="row">{elem.title}</td>
                                    <td>{elem.question}</td>
                                    <td>{elem.time}</td>
                                    <td>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )
                          })}
                    
                </tbody>
            </table>
                  <div class="pagin">
                      <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={onPageChange}
                      />
                  </div>
        </div>
    </div>
</div>

  )
}

export default CsNormalClasses