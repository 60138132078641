import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import  saveAs  from 'file-saver';
import { useForm } from "react-hook-form";
import { subject_all } from "../../../apis/Common";
import {getStudyMaterial} from "../../../apis/My-e-Test/Classes"
const SelfStudy = () => {
    const [subject, setSubject] = useState([]);
    const [study, setStudey] = useState([]);
    const [data,setData]=useState('');
    const [id,setId]=useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm();
    useEffect(() => {
        async function get_data() {
            const subject = await subject_all();
            const study = await getStudyMaterial(id);
            setStudey(study?.data);
            setSubject(subject?.data);
        }
        get_data();
    }, [id]);
   const zip = new JSZip();
const Downloadzip=()=>{
    // zip.file("Hello.txt", "Hello World\n");
    var img = zip.folder(""); 
    
    img.file(data, study, {base64: true});
    console.log('i',img.file(data, study, {base64: true}));
    zip.generateAsync({type:"blob"}).then(function(content) {
    
        saveAs(content, "csData.zip");
    });
}
console.log(data);

    return (
        <div className='lecture-sec'>
            <div className="row">
                <div className="col-12">
                    <div className="table_head choice_head">
                        <h2 className="">Lectures</h2>
                    </div>
                    <div className="checkout_field choice_field">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='lecture-header'>
                                <div className="marks">
                                    <h4>List Of Lectures</h4>
                                </div>
                                <form >
                                    <div className="info_field">
                                        <select className="form-select" name="course"
                                        onChange={(e)=>setId(e.target.value)}  >
                                            <option value="">Choose Subject</option>
                                            {subject?.map((elem) => {
                                                return (<option value={elem.id} >{elem.subject}</option>)
                                            })}
                                        </select>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                        <div className='row'>

                            <div className='col-12'>
                                {study?.map((ele)=>{
                                    return(
                                        <>
                                        <iframe src={ele.study_file} title="W3Schools Free Online Web Tutorials"></iframe>
                                        <button onClick={()=>{Downloadzip();setData(ele.study_file)}}>Download</button>
                                        </>
                                    )

                                })}


                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </div>

    )
}

export default SelfStudy;