import React from 'react';
import { get_grade } from '../../../apis/Common';
import { useState, useEffect } from 'react';
import { freeassemenent_test_list } from '../../../apis/My-e-Test/AssessmentTest';
import Pagination from '../../Pagination';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
const AssessmentFree = () => {
    let itemsPerPage = 5;
    const [grades, setGrades] = useState([]);
    const [testList, setTestList] = useState([]);
    const [question, setQuestion] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(testList?.length / itemsPerPage);

    useEffect(() => {
        async function get_data() {
            const grade = await get_grade();
            const testlist = await freeassemenent_test_list(currentPage);
            setGrades(grade?.data);
            setTestList(testlist?.data);
            // setQuestion(JSON.parse(testlist?.data[0]?.question_data))
        }
        get_data();
    }, []);
    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = testList?.slice(startIndex, endIndex);
    const gradeid = localStorage.getItem('grade')
    const selectedGrade = grades.find(elem => elem.id === gradeid);

    let generatePDF = () => {
        const doc = new jsPDF();
        // if (question) {
        //     const propertiesToDelete = ["answer"];
        //     const tableData = question.map((record) => {
        //         const filteredRecord = Object.keys(record)?.filter((key) => !propertiesToDelete?.includes(key))?.reduce((obj, key) => {
        //             obj[key] = record[key]; return obj;
        //         }, {});
        //         return Object.values(filteredRecord);
        //     });




        //     const tableHeaders = Object.keys(question[0]);

        //     doc.autoTable({
        //         columnStyles: {
        //             6: { cellWidth: 25 },
        //             7: { cellWidth: 30 },
        //         },
        //         head: [tableHeaders],
        //         body: tableData,
        //     });

        //     // doc.save('Userslist.pdf');
        //     doc.output('dataurlnewwindow');
        // }
        if (testList) {
            const propertiesToDelete = ["answer"];
            // const tableData = question.map((record) => {
            //   const filteredRecord = Object.keys(record)?.filter((key) => !propertiesToDelete?.includes(key))?.reduce((obj, key) => {obj[key] = record[key];return obj;
            //     }, {});
            //   return Object.values(filteredRecord);
            // });
            const data = question.map((elem) => {
                return (
                    `Question: ${elem.question}
                        Passage: ${elem.passage}
                        option1: ${elem.option1}
                        option2: ${elem.option2}
                        option3: ${elem.option3}
                        option4: ${elem.option4}
                        option5: ${elem.option5}
                          `
                )
            })
            const tableData = testList.map((record) => {
                // Flatten the cardDetails array and combine it with other fields
                const flattenedRecord = {
                    ...record
                    ,
                    question_data: JSON.parse(record.question_data).map((elem) => {
                        return `Question: ${elem.question}
              Passage: ${elem.passage}
              option1: ${elem.option1}
              option2: ${elem.option2}
              option3: ${elem.option3}
              option4: ${elem.option4}
              option5: ${elem.option5}
              ---------------------
                `
                    }).join("\n")
                };
                //   delete flattenedRecord.Nearby_driver;
                //   delete flattenedRecord.id;
                //   delete flattenedRecord.order_images;
                //   delete flattenedRecord.category_id;
                //   delete flattenedRecord.delievery_instruction;
                return Object.values(flattenedRecord);
            });


            const tableHeaders = Object.keys(testList[0]);
            delete tableHeaders.question_data
            doc.autoTable({
                columnStyles: {
                    9: { cellWidth: 20 },
                },
                // columnStyles: {
                //     3: {cellWidth: 20},
                //   },
                head: [tableHeaders],
                body: tableData,
            });
            // doc.save('Userslist.pdf');
            doc.output('dataurlnewwindow');
        }
    }
    return (
        <div className="row">
            <div className="col-12">
                <div className="grade_content">
                    <h2>Selected grade</h2>
                    <div className="date-slot activity-sec">
                        <ul>
                            {grades && grades.map((elem) => {
                                const { id, name } = elem;
                                return id == gradeid ? (
                                    <li key={id} className="list-group-item">
                                        <input type="radio" id={id} name="Time" />
                                        <a href="javascript:void(0);" className="">
                                            {/* SVG and other HTML elements */}
                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.70605 8.22878H18.302" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.3866 11.646H14.3947" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.5043 11.646H10.5124" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.61316 11.646H6.62127" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.3866 15.0466H14.3947" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.5043 15.0466H10.5124" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.61316 15.0466H6.62127" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.0381 1.75V4.62943" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.96975 1.75V4.62943" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M14.2085 3.13184H6.79959C4.22999 3.13184 2.625 4.56328 2.625 7.19448V15.1129C2.625 17.7855 4.22999 19.25 6.79959 19.25H14.2004C16.7781 19.25 18.375 17.8103 18.375 15.1791V7.19448C18.3831 4.56328 16.7862 3.13184 14.2085 3.13184Z"
                                                    stroke="#fff"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            {name}
                                        </a>
                                    </li>
                                ) : null;
                            })}


                        </ul>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="table_head">
                    <h2 className="">Assessment Test List</h2>
                    <p><b>* Free online tests</b> are strictly for students of <b>that grade.</b></p>
                </div>
                <div className="table-responsive tbl_bg">
                    {/* <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Subject</th>
                                <th scope="col">Duration</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentData && currentData?.map((elem) => {

                                    return (
                                        <tr>
                                            <td>{elem?.subject}</td>
                                            <td>{elem?.time} min</td>
                                            <td>Eroll Now</td>
                                            <td><Link target='_blank' className='edit-btn' to={`/my-e-test/questionaire/${elem?.id}`}>Take Test</Link></td>
                                        </tr>
                                    )

                                })
                            }
                        </tbody>
                    </table> */}

                    <table className="table">
                        <tbody>
                            {
                                currentData && currentData?.map((elem) => {

                                    return (
                                        <tr>
                                            <td>If you want to take the <span style={{ color: 'blue' }}>Year {`${elem?.grade}`} Assessment Tests</span>, please <Link target='_blank' className='edit-btn' to={`/my-e-test/questionaire/${elem?.id}`}>click here</Link>.</td>
                                           
                                        </tr>
                                    );

                                })
                            }
                        </tbody>
                    </table>
                    <div className="pagin">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
                <div className="tbl_content">
                    <ol type="1">
                        {/* <li>* If you are not enrolled in CS Online School, please <span className="join">Join Membership first</span> and then take the test.</li> */}
                        <li>* Assessment test must be completed within in 7 days from the time you click the button above.</li>
                        <li>* You can take the test ONLY ONCE during the available period.</li>
                    </ol>
                </div>
            </div>
        </div>

    )
}

export default AssessmentFree;