import './App.css';
import './assets/style.css';
import './assets/custom.css';
import 'react-toastify/dist/ReactToastify.css';
import CreateRoute from './routes/Routes';
function App() {
  return (
    <CreateRoute />
  );
}

export default App;
