import { HTTPURL } from '../../constants/Matcher';

export async function get_profile() {
    try {
        const response = await fetch(HTTPURL + 'api/student/student-register_edit'
            , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('Token')}`
                }
            });
        const responseData = await response.json();
        localStorage.setItem('userImage',responseData?.data[0].image_url)
        // if(responseData.status)
        // {
        //     (responseData?.message);
        // } else {
        //    (responseData?.message);
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_profiles() {
    try {
        const response = await fetch(HTTPURL + 'api/student/student-register_edits'
            , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('Token')}`
                }
            });
        const responseData = await response.json();
        localStorage.setItem('userImage',responseData?.data[0].image_url)
        // if(responseData.status)
        // {
        //     (responseData?.message);
        // } else {
        //    (responseData?.message);
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item update 
export async function update_profile(data) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('Token')}`);

    try {
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        };

        const response = await fetch(HTTPURL + `api/student/student-register_update`, requestOptions);
        const responseData = await response.json();
        // if (responseData.status) {
        //     toast.success(responseData?.message);
        // } else {
        //     toast.error(responseData?.message);
        // }
        // localStorage.setItem('UserName', responseData?.data[0].name)
        if (responseData.status) {
            // Update localStorage with the new name
            if (responseData.data && responseData.data[0]) {
                localStorage.setItem('name', responseData.data[0].name); 
                localStorage.setItem('userImage',responseData?.data[0].image_url)
            }

            // toast.success(responseData?.message);
        } else {
            // toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



