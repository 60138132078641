import React from 'react';
import { useNavigate } from 'react-router-dom';
import { consulting } from '../../../apis/My-e-Test/HomeWork';
import { useForm } from "react-hook-form";

const Consulting = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm();
    const navigate = useNavigate();

    

    const onSubmit = async (data) => {
        const res = await consulting(data);

        if (res?.status) {
            return navigate('/dashboard');
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="table_head choice_head">
                    <h2 className="">Consulting</h2>
                </div>
                <div className="checkout_field choice_field">
                    <div className='row'>
                        <div className='col-md-6 col-12'>
                            <div className="marks">
                                <h4>Subject & Message</h4>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className="marks">
                                <h4>Detail</h4>
                            </div>
                        </div>
                    </div>
                    <hr className="m-0" />
                    <div className='row'>
                        <div className='col-md-6 col-12'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-12">
                                <div className="ability">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Subject</label>
                                            <input type="text" className="form-control" id="exampleFormControlInput1"
                                                placeholder=""
                                                {...register("subject")} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">
                                            <div className="mb-12">
                                                <label for="exampleFormControlInput1" className="form-label">Message</label>
                                                <textarea className="form-control" name="description" rows="3"
                                                    {...register("message")}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="cut_sub_btn">
                                        <button type='submit'>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className="row">
                                <div className="col-12">
                                    <div className="col-12">
                                        <div className="profile_cont consulting">
                                            <div className="profile_inner_cont profile_inner_add">
                                                <h2>Branch: *</h2>
                                                <h4>branch 1</h4>
                                            </div>
                                            <div className="profile_inner_cont">
                                                <h2>Contact: *</h2>
                                                <h4>8765552368</h4>
                                            </div>
                                            <div className="profile_inner_cont">
                                                <h2>Address</h2>
                                                <h4>43 Savages Road  Queensland</h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </div>
        </div>

    )
}

export default Consulting