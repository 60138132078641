import React from 'react';
import { useState, useEffect } from 'react';
import { getClassList } from '../../../apis/My-e-Test/ClassTest';
import jsPDF from 'jspdf';
import Pagination from '../../Pagination';

const ClassReview = () => {
    let itemsPerPage = 5;
    const [workList, setWorkList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(workList?.length / itemsPerPage);

    // useEffect(() => {
    //     async function get_data() {
    //         const worklist = await getClassList(currentPage);
    //         setWorkList(worklist?.data);
    //     }
    //     get_data();
    // }, []);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = workList?.slice(startIndex, endIndex);


    const pdfDetail = () => {


    }

    return (
        <div className="row">
            <h1>Coming Soon.....</h1>
            {/* <div className="col-12">
                <div className="table_head">
                    <h2 className="">Class List</h2>
                </div>
                <div className="table-responsive tbl_bg">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Subject List</th>
                                <th scope="col">Assignment</th>
                                <th scope="col">Attachment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentData && currentData?.map((elem) => {
                                    return (
                                        <tr>
                                            <td scope="row">{elem?.subject}</td>
                                            <td>{`${elem?.movie_title}`}</td>
                                            <td>
                                                <a href='https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf} without rel=noopener noreferrer target=_blank' target='_blank' rel='noopener noreferrer'>
                                                    <button trailingIcon="picture_as_pdf" label="Resume">
                                                        View
                                                    </button>
                                                </a></td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className="pagin">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
                <div className="tbl_content">
                    <ol type="1">
                        <li>* If you are not enrolled in CS Online School, please <span className="join">Join Membership first</span> and then take the test.</li>
                        <li>You can take the test ONLY ONCE during the available period.</li>
                    </ol>
                </div>
            </div> */}
        </div>

    )
}

export default ClassReview;