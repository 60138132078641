import { HTTPURL } from '../constants/Matcher';

export async function student_course_payment_detail() {
    try {
        const response = await fetch(HTTPURL + 'api/student/stuent-course-class-payment-details',
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('singupToken')}`
            }
        });
        const responseData = await response.json();
        if (responseData.status) {
            // responseData?.message;`
        }
        // else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function student_payment_course_detail_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/student/payment-course',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('singupToken')}`
            },
            body:JSON.stringify(data)
        });
        const responseData = await response.json();
        // if (responseData.status == false) {
        //     responseData.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}