import { HTTPURL } from "../../constants/Matcher";

export async function get_all_schools() 
{
    try {
        const response = await fetch(HTTPURL + 'api/school-all');
        const responseData = await response.json();
        if (responseData.status) {
            return responseData;
        }
    } catch (error) {
        console.log(error.message);
        return error?.message;
    }
}