import React from 'react';
import { useEffect, useState } from 'react';
import { HTTPURL } from "../../../constants/Matcher";
import { get_website_data } from '../../../apis/WebsiteEnv';
const WebsitEnvDetail = () => {
    const [envView, setEnvView] = useState([]);
    useEffect(() => {
        async function get_data() {
            const envView = await get_website_data();
            setEnvView(JSON.parse(envView?.data[0]?.env_data));
        }
        get_data();
    }, []);
    return (
        <div className="row">
            <div className="col-12">
                <div className="profile_shade">
                    <div className="grade_content profile_title">
                        <h2>Website Env Detail</h2>

                    </div>
                    <hr className="m-0" />
                    <div className="row">
                        <div className="col-12">
                            <div className="profile_cont">
                                <div className="profile_inner_cont">
                                    <h2>Max Week Term(1)</h2>
                                    <h4>{envView?.m_w_term1 ? envView?.m_w_term1 : '-'}</h4>
                                </div>
                                <div className="profile_main">
                                    <div className="profile_inner_cont ">
                                        <h2>Max Week Term(2)</h2>
                                        <h4>{envView?.m_w_term2 ? envView?.m_w_term2 : '-'}</h4>

                                    </div>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Max Week Term(3)</h2>
                                    <h4>{envView?.m_w_term3 ? envView?.m_w_term3 : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Max Week Term(4)</h2>
                                    <h4>{envView?.m_w_term4 ? envView?.m_w_term4 : '-'}</h4>
                                </div>
                            </div>
                            <div className="profile_cont">
                                <div className="profile_main">
                                    <div className="profile_inner_cont">
                                        <h2>Start Date Term(1)</h2>
                                        <h4>{envView?.s_d_term1 ? envView?.s_d_term1 : '-'}</h4>
                                    </div>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Start Date Term(2)</h2>
                                    <h4>{envView?.s_d_term2 ? envView?.s_d_term2 : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Start Date Term(3)</h2>
                                    <h4>{envView?.s_d_term3 ? envView?.s_d_term3 : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Start Date Term(4)</h2>
                                    <h4>{envView?.s_d_term4 ? envView?.s_d_term4 : '-'}</h4>
                                </div>
                            </div>
                            <div className="profile_cont">
                                <div className="profile_inner_cont">
                                    <h2>Current Term</h2>
                                    <h4>{envView?.c_term ? envView?.c_term : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Current Year</h2>
                                    <h4>{envView?.c_year ? envView?.c_year : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Classtest Barnches</h2>
                                    <h4>{envView?.ct_branches ? envView?.ct_branches : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Default Period 4 PA</h2>
                                    <h4>{envView?.e_d_period ? envView?.e_d_period : '-'}</h4>
                                </div>
                            </div>
                            <div className="profile_cont">
                                <div className="profile_inner_cont">
                                    <h2>Sdate G</h2>
                                    <h4>{envView?.g_date ? envView?.g_date : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Sdate M</h2>
                                    <h4>{envView?.m_date ? envView?.m_date : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Sdate E</h2>
                                    <h4>{envView?.s_date ? envView?.s_date : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Sdate W</h2>
                                    <h4>{envView?.w_date ? envView?.w_date : '-'}</h4>
                                </div>
                            </div>
                            <div className="profile_cont">
                                <div className="profile_inner_cont">
                                    <h2>Test Period</h2>
                                    <h4>{envView?.t_period ? envView?.t_period : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Holi Off Test Review Period</h2>
                                    <h4>{envView?.h_period ? envView?.h_period : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Off Test Review Period</h2>
                                    <h4>{envView?.ot_period ? envView?.ot_period : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Test Review Period</h2>
                                    <h4>{envView?.r_period ? envView?.r_period : '-'}</h4>
                                </div>
                            </div>
                            <div className="profile_cont">
                                <div className="profile_inner_cont">
                                    <h2>Review Date</h2>
                                    <h4>{envView?.r_date ? envView?.r_date : '-'}</h4>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Unit Test Start Week</h2>
                                    <h4>{envView?.ut_s_week ? envView?.ut_s_week : '-'}</h4>
                                </div>
                            </div>
                            <hr className="m-0" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WebsitEnvDetail;
