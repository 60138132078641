import { HTTPURL } from "../../constants/Matcher";

export const assemenent_test_list = async(currentPage) => 
{
    try {
        // let local = 'http://127.0.0.1:4040/'
        const response = await fetch(HTTPURL+`api/student/student-test-types?page=${currentPage}`
        , {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('Token')}`
            }
        });
        const responseData = await response.json();
        return responseData;  
    } catch (error) {
        return error?.message;
    }
}


export const freeassemenent_test_list = async(currentPage) => 
{
    try {
        // let local = 'http://127.0.0.1:4040/'
        const response = await fetch(HTTPURL+`api/student/student-free-test-types?page=${currentPage}`
        , {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('Token')}`
            }
        });
        const responseData = await response.json();
        return responseData;  
    } catch (error) {
        return error?.message;
    }
}