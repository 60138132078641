import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
function RequireAuthStudent() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  checkToken();
  if (!userData) {
    return <Navigate to='/login' />
  }
  // Assuming userData has a 'roles' property to represent user roles
  if (userData?.data?.role === 'student') {
    return <Outlet />;
  }
  return <h3>You are not logged in, Please log in first!</h3>;
}

function StudentLogout() {
  localStorage.clear();
  localStorage.removeItem("userData");
  return <Navigate to='/login' />
}

function checkToken() {
  var now = new Date().getTime();
  var setupTime = JSON.parse(localStorage.getItem('setupTime'));
  if (setupTime != null) {
    console.log("check user is logged in or not...")
    if (now - setupTime > 60 * 60 * 3000) {
      localStorage.clear();
      localStorage.removeItem("Token");
    }
  }
}
const ProtectedRoute = ({ children }) => {
  const token = window.localStorage.getItem("Token");
  if (!token) {
    return <Navigate to="/login" />;
  }
  else {
    return children;
  }
};
const AfterLogin = ({ children }) => {
  const token = window.localStorage.getItem("Token");
    if (token) {
    return <Navigate to="/dashboard" />;
  }
  else {
    return children;
  }
};

export { RequireAuthStudent, StudentLogout, ProtectedRoute, AfterLogin }
