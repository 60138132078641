import React from 'react'

const SelectiveHighSchool = () => {
  return (
    <div>
      <div class="row">
    <div class="col-12">
        <div class="selective_content">
            <div class="selective_head">
                <h4>What is a Selective High School?</h4>
            </div>
            <ol class="selective_list">
                <li>Selective Schools are government high schools operated by the New South Wales Department of Education and Training.</li>
                <li>They have accepted their students based upon their academin merit.</li>
                <li>There are currently 17 full selective schools, 23 partially selective schools, 4 agricultural high schools and 1 virtual selective school provision.</li>
                <li>The aim of a selective school is to provide a scholastically challenging environment for more academically able students</li>
            </ol>
        </div>

        <div class="selective_content">
            <div class="selective_head">
                <h4>Selective High Schools in NSW</h4>
            </div>
            <ol class="selective_list">
                <li>Selective Schools are government high schools operated by the New South Wales Department of Education and Training.</li>
                <li>They have accepted their students based upon their academin merit.</li>
                <li>There are currently 17 full selective schools, 23 partially selective schools, 4 agricultural high schools and 1 virtual selective school provision.</li>
                <li>The aim of a selective school is to provide a scholastically challenging environment for more academically able students</li>
            </ol>
        </div>

        <div class="table-responsive tbl_bg study_attd selective">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">1. Fully Selective/Academic</th>
                        <th scope="col">2010</th>
                        <th scope="col">2008</th>
                        <th scope="col">2002</th>
                        <th scope="col">1988</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td scope="row">Single sex (boys)</td>
                        <td>4</td>
                        <td>4</td>
                        <td>4</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td scope="row">Single sex (girls)</td>
                        <td>4</td>
                        <td>7</td>
                        <td>3</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td scope="row">Co-educational</td>
                        <td>4</td>
                        <td>6</td>
                        <td>5</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <th scope="row">Total</th>
                        <th>12</th>
                        <th>17</th>
                        <th>12</th>
                        <th>12</th>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive tbl_bg study_attd selective full_select">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">2. Fully Selective/Academic Agricultural</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td scope="row">Single sex (boys)</td>
                        <td>3</td>
                        <td>3</td>
                        <td>3</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td scope="row">Single sex (girls)</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td scope="row">Co-educational</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <th scope="row">Total</th>
                        <th>4</th>
                        <th>4</th>
                        <th>4</th>
                        <th>4</th>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive tbl_bg study_attd selective full_select">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">2. Fully Selective/Academic Agricultural</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Total</th>
                        <th>4</th>
                        <th>4</th>
                        <th>4</th>
                        <th>4</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
  
    </div>
  )
}

export default SelectiveHighSchool