import React from 'react'
import { Link } from 'react-router-dom'

const Cart = () => {
  return (
    <div class="row">
    <div class="col-12">
        <div class="table_head">
            <h2 class="">Your Cart</h2>
        </div>
        <div class="table-responsive tbl_bg cart">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Period</th>
                        <th scope="col">Price</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td scope="row">1.</td>
                        <td>NAPLAN 2008 Y3 Past Paper(L.R.N)</td>
                        <td>E.M.G</td>
                        <td>7 Days</td>
                        <td>$75</td>
                        <td>
                            <div class="cross_icon">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.05 9L14.775 4.275C15.075 3.975 15.075 3.525 14.775 3.225C14.475 2.925 14.025 2.925 13.725 3.225L9 7.95L4.275 3.225C3.975 2.925 3.525 2.925 3.225 3.225C2.925 3.525 2.925 3.975 3.225 4.275L7.95 9L3.225 13.725C3.075 13.875 3 14.025 3 14.25C3 14.7 3.3 15 3.75 15C3.975 15 4.125 14.925 4.275 14.775L9 10.05L13.725 14.775C13.875 14.925 14.025 15 14.25 15C14.475 15 14.625 14.925 14.775 14.775C15.075 14.475 15.075 14.025 14.775 13.725L10.05 9Z"
                                        fill="#EE3124"
                                    />
                                </svg>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td scope="row">
                            2.
                        </td>
                        <td>NAPLAN 2008 Y3 Past Paper(W)</td>
                        <td>Essay Writing</td>
                        <td>7 Days</td>
                        <td>$15</td>
                        <td>
                            <div class="cross_icon">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.05 9L14.775 4.275C15.075 3.975 15.075 3.525 14.775 3.225C14.475 2.925 14.025 2.925 13.725 3.225L9 7.95L4.275 3.225C3.975 2.925 3.525 2.925 3.225 3.225C2.925 3.525 2.925 3.975 3.225 4.275L7.95 9L3.225 13.725C3.075 13.875 3 14.025 3 14.25C3 14.7 3.3 15 3.75 15C3.975 15 4.125 14.925 4.275 14.775L9 10.05L13.725 14.775C13.875 14.925 14.025 15 14.25 15C14.475 15 14.625 14.925 14.775 14.775C15.075 14.475 15.075 14.025 14.775 13.725L10.05 9Z"
                                        fill="#EE3124"
                                    />
                                </svg>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="coupon_content">
            <h4>Have a coupon?</h4>
            <div class="coupon_inner">
                <input type="text" placeholder="#3543243424234ASDDFF" />
                <button type="button">Apply Coupon</button>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="imp_content">
            <h4>*Important !</h4>
            <ol type="1">
                <li>You can confirm all your selected classes and materials in your cart.</li>
                <li>The classes and materials in your cart will be retained only for 7 days.</li>
                <li>You can put as many classes and materials as you wish in your cart, and then can pay for them altogether.</li>
            </ol>
        </div>
    </div>

    <div class="col-12">
        <div class="cart_total">
            <h4>Cart totals</h4>
            <table class="table">
                <tbody>
                    <tr>
                        <th scope="row" style={{"width" : "180px;"}}>Subtotal</th>
                        <td>$90</td>
                    </tr>
                    <tr>
                        <th scope="row">Discount</th>
                        <td>-$25</td>
                    </tr>
                    <tr>
                        <th scope="row">Total</th>
                        <td>$65</td>
                    </tr>
                </tbody>
            </table>
            <div class="checkout_btn">
                <Link to="/carts/checkout">Proceed to checkout</Link>
            </div>
        </div>
    </div>
</div>

  )
}

export default Cart