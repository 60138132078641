// src/components/Pagination.js
// import "../App.css"
import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  // console.log('pageNumbers', pageNumbers);
  // console.log('totalPages', totalPages);
  // console.log('currentPage', currentPage);
  return (
    <ul className="pagination">
      {pageNumbers?.map((page) => (
        <li
          key={page}
          className={`page-item ${currentPage === page ? 'active' : ''}`}
        >
          <button
            className="page-link"
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
