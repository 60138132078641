import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getlectureData, enroll_student_payment } from "../../../apis/My-e-Test/Classes";
import { get_branch } from '../../../apis/Common';
import Pagination from '../../Pagination';
import { Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
//import './Classes.css'; // Include a CSS file for custom styling

const Classes = () => {
    const [open, setOpen] = useState(false);
    const itemsPerPage = 5;
    const [lectureData, setLectureData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedRows, setExpandedRows] = useState({});
    const [selectedSubject, setSelectedSubject] = useState({});
    const [branch, setBranch] = useState([]);
    const [branchId, setBranchId] = useState('');
    const [enrollStatus, setEnrollStatus] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const grade = localStorage.getItem('grade');
    const userID = localStorage.getItem('studentId');
    const totalPages = Math.ceil(lectureData.length / itemsPerPage);

    useEffect(() => {
        async function get_data() {
            const lecturlist = await getlectureData(currentPage);
            const branchData = await get_branch();
            setLectureData(lecturlist?.data || []);
            setBranch(branchData?.data || []);
        }
        get_data();
    }, [currentPage]);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const submitData = async (e) => {
        e.preventDefault();
        const formData = {
            'grade_id': grade,
            'user_id': userID,
            'total_price': 10,
            'branch_id': branchId,
            'subject_id': 0,
            'payment_method': paymentMethod
        };
        if (enrollStatus) {
            const res = await enroll_student_payment(formData);
            if (res?.status) {
                setOpen(false);
                localStorage.setItem('studentType', 1);
            }
        } else {
            toast.error('Enrolled status is required');
        }
    };

    const toggleExpandRow = (courseId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [courseId]: !prev[courseId]
        }));
    };

    const handleSubjectSelect = (courseId, termId, subjectId) => {
        setSelectedSubject((prev) => ({
            ...prev,
            [courseId]: {
                ...prev[courseId],
                [termId]: subjectId
            }
        }));
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = lectureData.slice(startIndex, endIndex);
    const studentType = localStorage.getItem('studentType');

    return (
        <>
            <div className="col-12">
                <div className="row">
                    <div className="table_head">
                        <h2>Lectures</h2>
                    </div>
                    <div className="table-responsive tbl_bg">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ width: '5%' }}></th> {/* For expansion toggle */}
                                    <th scope="col" style={{ width: '15%' }}>Sr No</th>
                                    <th scope="col" style={{ width: '25%' }}>Course</th>
                                    <th scope="col" style={{ width: '20%' }}>Grade</th>
                                    <th scope="col" style={{ width: '20%' }}>Course Type</th>
                                    <th scope="col" style={{ width: '15%' }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData.length > 0 ? currentData.map((course, index) => (
                                    <React.Fragment key={course.course_id}>
                                        <tr>
                                            <td onClick={() => toggleExpandRow(course.course_id)} style={{ cursor: 'pointer' }}>
                                                {expandedRows[course.course_id] ? '▼' : '►'}
                                            </td>
                                            <td>{startIndex + index + 1}</td>
                                            <td>{course.course_name}</td>
                                            <td>{course.grade}</td>
                                            <td>{course.course_type_name}</td>
                                            <td>
                                                {studentType === '1'
                                                    ? <span>Active</span>
                                                    : <button className='edit-btn' onClick={() => setOpen(true)}>Enroll Now</button>}
                                            </td>
                                        </tr>
                                        {expandedRows[course.course_id] && (
                                            <tr>
                                                <td colSpan="6" style={{ paddingLeft: '2rem' }}> {/* Align child row */}
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '5%' }}>S.NO</th>
                                                                <th style={{ width: '20%' }}>Term</th>
                                                                <th style={{ width: '30%' }}>Subject</th>
                                                                <th style={{ width: '15%' }}>View</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {course.terms.map((term, termIndex) => (
                                                                <React.Fragment key={term.term_id}>
                                                                    <tr>
                                                                        <td>{termIndex + 1}</td>
                                                                        <td>{term.term_name}</td>
                                                                        <td>
                                                                            <select
                                                                                onChange={(e) => handleSubjectSelect(course.course_id, term.term_id, e.target.value)}
                                                                                value={selectedSubject[course.course_id]?.[term.term_id] || ''}
                                                                            >
                                                                                <option value="" disabled>Select Subject</option>
                                                                                {term.subjects.map((subject) => (
                                                                                    <option key={subject.subject_id} value={subject.subject_id}>
                                                                                        {subject.subject_name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <Link
                                                                                className="edit-btn"
                                                                                to={`/Classes/lecture-list/${course.course_id}/${term.term_id}/${selectedSubject[course.course_id]?.[term.term_id] || ''}`}
                                                                                onClick={(e) => {
                                                                                    if (!selectedSubject[course.course_id]?.[term.term_id]) {
                                                                                        e.preventDefault();
                                                                                        toast.warning("Please select a subject.");
                                                                                    }
                                                                                }}
                                                                            >
                                                                                View
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                )) : <tr><td colSpan="6">No Data</td></tr>}
                            </tbody>
                        </table>
                        <div className="pagin">
                            <Pagination
                                onPageChange={onPageChange}
                                totalPages={totalPages}
                                currentPage={currentPage}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Enroll Now</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitData}>
                        <div className="form-group">
                            <label>Branch:</label>
                            <select value={branchId} onChange={(e) => setBranchId(e.target.value)}>
                                {branch.map((option) => (
                                    <option key={option.branch_id} value={option.branch_id}>{option.branch_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Payment Method:</label>
                            <select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                                <option value="1">Credit Card</option>
                                <option value="2">PayPal</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Enroll Status:</label>
                            <input type="checkbox" checked={enrollStatus} onChange={() => setEnrollStatus(!enrollStatus)} />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Classes;
