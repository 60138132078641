import { HTTPURL } from "../../constants/Matcher";




export async function getClassList() {
    try {
        const response = await fetch(HTTPURL + 'api/student/get-class-list'
            , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('Token')}`
                }
            });
        const responseData = await response.json();
        // if(responseData.status)
        // {
        //     (responseData?.message);
        // } else {
        //    (responseData?.message);
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function getquestionList(id) {
    try {
        const response = await fetch(HTTPURL + `api/student/get-question-year/?c_id=${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('Token')}`
                }
            });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function add_Questionaire(payload) {

    try {
        const response = await fetch(HTTPURL + 'api/student/class-question-answer-save', {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('Token')}`
             },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (responseData?.status) {
            console.log('name', responseData?.data);
        }
        // else {
        //   responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

