import React from 'react';
import { get_all_schools } from '../../../apis/my-academic-info/School';
import { useState,useEffect } from 'react';

const MySchoolChoices = () => {
    const [schoolList, setSchoolList] = useState([]);

    async function get_Data() {
        const result = await get_all_schools();
        setSchoolList(result?.data);
    }

    useEffect(() => {
        get_Data();
    },[]);
    return (
        <div className="row">
            <div className="col-12">
                <div className="table_head choice_head">
                    <h2 className="">Selective High School Choices</h2>
                </div>
                <div className="checkout_field choice_field">
                    <div className="row">
                        <div className="col-12">
                            <div className="marks">
                                <h4>Test & Marks</h4>
                            </div>
                            <hr className="m-0" />
                            <div className="row">
                                <form>
                                    <div className="col-12">
                                        <div className="ability">
                                            <div className="choice_radio">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label" for="flexRadioDefault1">
                                                        Assessment
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                    <label className="form-check-label" for="flexRadioDefault2">
                                                        Selective Trial
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                                    <label className="form-check-label" for="flexRadioDefault3">
                                                        Selective Trial Online
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">English</label>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Writing</label>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Mathematics</label>
                                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">General Ability</label>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="marks">
                                            <h4>School Choice</h4>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="cut_off">
                                                    <div className="row">
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="mb-3">
                                                                <select className="form-select" aria-label="Default select example">
                                                                    <option value="">1st Choice</option>
                                                                    {
                                                                        schoolList && schoolList?.map((elem) => {
                                                                            return(
                                                                                <option value={elem?.id}>{elem?.school_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="mb-3">
                                                                <select className="form-select" aria-label="Default select example">
                                                                <option value="">2nd Choice</option>
                                                                    {
                                                                        schoolList && schoolList?.map((elem) => {
                                                                            return(
                                                                                <option value={elem?.id}>{elem?.school_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="mb-3">
                                                                <select className="form-select" aria-label="Default select example">
                                                                <option value="">3rd Choice</option>
                                                                    {
                                                                        schoolList && schoolList?.map((elem) => {
                                                                            return(
                                                                                <option value={elem?.id}>{elem?.school_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cut_head">
                                                        <h4>Cut-Off</h4>
                                                    </div>
                                                    <div className="choice_radio">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                                            <label className="form-check-label" for="flexRadioDefault4">
                                                                1st Round
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                                            <label className="form-check-label" for="flexRadioDefault5">
                                                                2nd Round
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="cut_sub_btn">
                                                        <a href="#">Submit</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="guideline">
                    <div className="guideline_head">
                        <h4>Guideline for Selective High School Choices</h4>
                    </div>
                    <div className="text_para">
                        <p>
                            Entry into Selective High Schools in Year 7 is determined by the students’ results in the Selective High School Placement Tests in English (including Reading & Writing), Mathematics and General Ability together with
                            their primary school’s assessment of their performance in English and Mathematics.
                        </p>
                        <p>For the purpose of consultation on school choices, CS Education Selective High School Choice Guideline has been carefully formulated based on the following statistics & information.</p>
                    </div>
                    <div className="guideline_list">
                        <ol>
                            <li>CS Education Trial Tests scores.</li>
                            <li>
                                CS Education Assessment Tests scores including CS Class Tests or Diagnostic tests. The scores for all these tests may vary as level of difficulty for such assessment is different. Selective Trial Test marks are the
                                most difficult to score.
                            </li>
                            <li>CS Online Trial Tests</li>
                            <li>Analysis of past students’ records which include CS Education Selective Trial Test scores & actual scores in the Selective High School Placement Test.</li>
                            <li>
                                First round offer scores reflect only the minimum marks for students’ school choices. The scores are derived from our past students. First round scores are not released by the Department of Education & Training. Our
                                students’ Selective Trial Test scores are generally lower compared to the actual Selective High School marks.
                            </li>
                            <p>
                                For example: *240 marks actual test / about 230 in CS Trial test *CS Class tests/diagnostic tests marks are usually higher than actual scores or CS Trial Tests: approximately 255 marks *CS Online Trial Test scores
                                are almost similar to the actual scores.
                            </p>
                            <li>Second round cut-off scores are the minimum marks for students on reserved list who finally made it into a selective school. This should not be used as indication of the actual mark when making school choices.</li>
                        </ol>
                    </div>
                    <div className="text_para">
                        <p>Disclaimer Clause: Information provided is for the purpose of CS Education Consultation with parents. CS Education cannot accept liability for any repercussions from the use of this information.</p>
                    </div>
                    <div className="consul">
                        <h4>Consultaion</h4>
                        <ol>
                            <li>Tel: 9659 6433 Email:</li>
                            <li>cseducation@gmail.com</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MySchoolChoices