import React from 'react';
import { e_tutoring } from '../../../apis/My-e-Test/HomeWork';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

const ETutoring = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm();
    const navigate = useNavigate();



    const onSubmit = async (data) => {
        const res = await e_tutoring(data);

        if (res?.status) {
            return navigate('/dashboard');
        }
    }



    return (
        <div className="row">
            <div className="col-12">
                <div className="table_head choice_head">
                    <h2 className="">E-Tutoring</h2>
                </div>
                <div className="checkout_field choice_field">
                    <div className='row'>
                        <div className='col-md-6 col-12'>
                            <div className="marks">
                                <h4>Subject & Message</h4>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className="profile_add e-tutor">
                                <h2>Detail</h2>
                            </div>
                        </div>
                    </div>
                    <hr className="m-0" />
                    <div className='row'>
                        <div className='col-md-6 col-12'>
                            <div className="row">
                                <div className="col-12">
                                   
                                  
                                    <div className="row">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="col-12">
                                                <div className="ability">
                                                    <div className="col-md-12 col-lg-12">
                                                        <div className="mb-3">
                                                            <label for="exampleFormControlInput1" className="form-label">Subject</label>
                                                            <input type="text" className="form-control" id="exampleFormControlInput1"
                                                                {...register("subject")} placeholder="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-12">
                                                            <div className="mb-12">
                                                                <label for="exampleFormControlInput1" className="form-label">Message</label>
                                                                <textarea className="form-control" name="description"
                                                                    {...register("message")} rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="cut_sub_btn">
                                                        <button>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className="row">
                                <div className="col-12">
                                    <div className="profile_cont e-tut">
                                        <div className="profile_inner_cont ">
                                            {/* <h2>Grade: *</h2> */}
                                            <h4>year 1</h4>
                                            <h4>oc</h4>
                                        </div>
                                        <div className="profile_inner_cont">
                                            {/* <h2>Terms: *</h2> */}
                                            {/* <h4>oc</h4> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                   

                </div>

            </div>
        </div>

    )
}

export default ETutoring