import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { student_course_payment_detail, student_payment_course_detail_add } from "../../apis/Payments"
const Payments = () => {
    const [payementDetail, setPaymentDetail] = useState({});
    const [amount, setAmount] = useState(0)
    const [lessonData, setLessonData] = useState([])
    const [paymentMethod, setPaymentMethod] = useState('');
    useEffect(() => {
        async function get_data() {
            const data = await student_course_payment_detail();
            if (data?.status) {
                setPaymentDetail(data?.data[0]);
                const countArray = JSON.parse(data?.data[0]?.lessonJsonData);
                setAmount(data?.data[0]?.movie_price ? countArray.length * data?.data[0]?.movie_price : '  ')
                setLessonData(JSON.parse(data?.data[0]?.lessonJsonData))
                // setAnswerData(JSON.parse(question?.data[0]?.answer_data))
            }
        }
        get_data();
    }, []);
    const navigate = useNavigate();
    console.log('paymentMethod', paymentMethod);
    const submitData = async () => {
        const formData = {
            'course_id': payementDetail?.course_id,
            'grade_id': payementDetail?.grade,
            'class_id': payementDetail?.class_id,
            'total_price': amount?amount:10,
            'branch_id': payementDetail?.branch,
            'subject_id': payementDetail?.subject_id,
            'payment_method': paymentMethod
        }
        const res = await student_payment_course_detail_add(formData);
        if (res?.status) {
            return navigate('/dashboard');
        }
    }
    return (
        <div className="row">
            <div className="col-12">
                <div className="profile_shade">
                    <div className="grade_content profile_title">
                        <h2>Payment Detail</h2>
                    </div>
                    <hr className="m-0" />
                    <div className="row">
                        <div className="col-12">
                            <div className="profile_cont">
                                <div className="profile_inner_cont">
                                    <h2>Course Name</h2>
                                    <h4>{payementDetail?.name}</h4>
                                </div>
                                <div className="profile_main">
                                    <div className="profile_inner_cont ">
                                        <h2>Class Name</h2>
                                        <h4>{payementDetail?.c_name}</h4>
                                    </div>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Subject</h2>
                                    <h4>{payementDetail?.subject}</h4>
                                </div>
                                {/* <div className="profile_main">
                                    <div className="profile_inner_cont">
                                        <h2>Grade</h2>
                                        <h4>{payementDetail?.grade}</h4>
                                    </div>
                                </div> */}
                            </div>
                            <div className="profile_cont">
                                <div className="profile_main">
                                    <div className="profile_inner_cont">
                                        <h2>Grade</h2>
                                        <h4>{payementDetail?.grade}</h4>
                                    </div>
                                </div>
                                <div className="profile_inner_cont">
                                    <h2>Unit Price</h2>
                                    <h4>{payementDetail?.movie_price}AUD</h4>
                                </div>
                            </div>
                            <hr className="m-0" />
                            <div className="profile_add">
                                <h2>Lessons</h2>
                            </div>
                            <hr className="m-0" />
                            <div className="row">
                                {lessonData && lessonData?.map((elem) => {
                                    return (
                                        <>
                                            <div className="col-12">
                                                <div className="profile_cont">
                                                    <div className="profile_inner_cont">

                                                        <h4>{elem.lessons}</h4>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                                <div className="profile_inner_cont">
                                    <h2>Total Amount</h2>
                                    <h4>AUD{amount}</h4>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor='enrolled'>Payment Method </label>
                                            <div className='highlight_inner'>
                                                <div className=" checkbox">
                                                    <label>
                                                        <input type="radio" name='payment_method' id="enrolled" value="cash"
                                                            onChange={(e) => setPaymentMethod(e.target.value)} /> Cash </label>
                                                </div>
                                                <div className=" checkbox">
                                                    <label>
                                                        <input type="radio" name='payment_method' id="enrolled" value="online"
                                                            onChange={(e) => setPaymentMethod(e.target.value)} /> Online </label>
                                                </div>
                                                <div className=" checkbox">
                                                    <label>
                                                        <input type="radio" name='payment_method' id="enrolled" value="paylater"
                                                            onChange={(e) => setPaymentMethod(e.target.value)} /> Pay Later </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="m-0" />
                        </div>
                        <div className="next_btn member_sub_btn">
                            <button type="button" onClick={() => submitData()} >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Payments;
