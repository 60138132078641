import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { HTTPURL } from '../../../constants/Matcher';
import { getlectureData_List } from '../../../apis/My-e-Test/Classes';
import jsPDF from 'jspdf';

const LectureDetail = () => {
    const [lectureList, setLectureList] = useState([]);
    const [lessonData, setLessonData] = useState([]);
    const [lectureData, setLectureData] = useState([]);
    const { course_id, term_id, subject_id } = useParams();

    useEffect(() => {
        async function get_data() {
            const lectureListResponse = await getlectureData_List(course_id, term_id, subject_id);
            console.log("----check lecture data", lectureListResponse);
            
            // Set lecture list data
            const lectureDataArray = lectureListResponse?.data?.data;
            console.log(lectureDataArray,"asd$$$$");
            
            setLectureList(lectureDataArray || []);
            
            // Set lesson data from question_data field of the first element
            // if (lectureDataArray?.length > 0) {
            //     setLessonData(JSON.parse(lectureDataArray[0]?.question_data || '[]'));
            // }

            // Set lectures data
            setLectureData(lectureListResponse?.data?.lectures || []);
        }
        get_data();
    }, [course_id, term_id, subject_id]);

    const handleOpenPDF = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="profile_shade">
                    <div className="grade_content profile_title">
                        <h2>Lecture</h2>
                    </div>
                    <hr className="m-0" />
                    <div className="row">
                        {lectureList.map((elem) => {
                            // const formattedDate = new Date(elem?.created_at).toISOString().substring(0, 10);

                            return (
                                <div key={elem.id} className="col-12">
                                    <div className="profile_cont">
                                        <div className="profile_inner_cont">
                                            <h2>Course</h2>
                                            <h4>{elem.course_name || 'N/A'}</h4>
                                        </div>
                                        <div className="profile_cont">
                                            <div className="profile_inner_cont">
                                                <h2>Term</h2>
                                                <h4>{elem.term_name || 'N/A'}</h4>
                                            </div>
                                        </div>
                                        <div className="profile_cont">
                                            <div className="profile_inner_cont"> 
                                                <h2>Grade</h2>
                                                <h4>{elem.grade ? `Year ${elem.grade}` : 'N/A'}</h4>
                                            </div>
                                        </div>
                                        <div className="profile_cont">
                                            <div className="profile_inner_cont"> 
                                                <h2>Subject</h2>
                                                <h4>{elem.subject_name || 'N/A'}</h4>
                                            </div>
                                        </div>
                                        {/* <div className="profile_cont">
                                            <div className="profile_main">
                                                <div className="profile_inner_cont">
                                                    <h2>Time</h2>
                                                    <h4>{elem.time ? `${elem.time} min` : 'N/A'}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="profile_cont">
                                            <div className="profile_main">
                                                <div className="profile_inner_cont">
                                                    <h2>Time</h2>
                                                    <h4>{elem.time ? `${elem.time} min` : 'N/A'}</h4>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>

                                    <hr className="m-0" />

                                    <div className="profile_add">
                                        <h2>Lecture Detail</h2>
                                    </div>
                                    
                                    {/* <div className='question-lec'><b>Questions</b></div>
                                    {lessonData.map((lesson, i) => (
                                        <div key={lesson.unique_id} className="profile_cont questionair-sec">
                                            <div className="profile_inner_cont profile_inner_add">
                                                <span>({i + 1}) {lesson.question}</span>
                                            </div>
                                        </div>
                                    ))} */}

                                    {lectureData.map((lecture, i) => (
                                        <div key={lecture.unit_id} className="profile_cont questionair-sec">
                                             {i === 0 && (
                                                <div className="profile_inner_cont">
                                                    <h2>Start Date</h2>
                                                    <h4>{lecture.start_date || 'N/A'}</h4>
                                                </div>
                                            )}
                                            <div className="profile_inner_cont">
                                                    <h2>Unit</h2>
                                                    <h4>
                                                    {lecture.unit_id}
                                                    </h4>
                                                </div>
                                             <div className="profile_inner_cont">
                                                    <h2>Link</h2>
                                                    <h4>
                                                        <span 
                                                            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} 
                                                            onClick={() => window.open(lecture.link, '_blank')} // Open in a new tab
                                                        >
                                                            {lecture.link || '--'}
                                                        </span>
                                                    </h4>
                                                </div>
                                            <div className="profile_inner_cont">
                                                    <h2>Image</h2>
                                                    <img 
                                                        src={`${HTTPURL}uploads/courses/${lecture.u_image}`} 
                                                        alt="" 
                                                        style={{ maxHeight: '200px', maxWidth: '50%', objectFit: 'contain' }} 
                                                        onError={(e) => { 
                                                            e.target.onerror = null; 
                                                            e.target.src = `${HTTPURL}uploads/cs_courses.jpg`; 
                                                        }} 
                                                    />
                                                </div>
                                            <div className="profile_inner_cont profile_inner_add">
                                                <h2>Document</h2>
                                                <h4>{lecture.u_document}</h4>
                                                <button onClick={() => handleOpenPDF(`${HTTPURL}uploads/courses/${lecture.u_document}`)}>Download</button>
                                            </div>
                                            <div className="profile_inner_cont">
                                                <h2>Audio</h2>
                                                <audio controls>
                                                    <source src={`${HTTPURL}uploads/courses/${lecture.u_audio}`} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                            <div className="profile_inner_cont profile_inner_add">
                                                <h2>Questionaire</h2>
                                                <td><Link target='_blank' className='edit-btn' to={`/my-e-test/questionaire/${lecture?.q_id}`}>Take Test</Link></td>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LectureDetail;
