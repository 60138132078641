import { HTTPURL } from '../constants/Matcher';
import { toast } from "react-toastify";

export async function get_events() {
    try {
        const response = await fetch(HTTPURL + 'api/student/student-events-all',{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('Token')}`
            }
        });
        const responseData = await response.json();
        if (responseData.status) {
            // responseData?.message;
        }
        // else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function events_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/event-edit?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_testType() {
    try {
        const response = await fetch(HTTPURL + 'api/test-types-all');
        const responseData = await response.json();
        if (responseData.status) {
            // responseData?.message;
        }
        // else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_student_question_list() {
    try {
        const response = await fetch(HTTPURL + 'api/student/get-student-question-list-by-branch-time',
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('Token')}`
            }
        });
        const responseData = await response.json();
        if (responseData.status) {
            // responseData?.message;
        }
        // else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



export async function get_course_by_grade() {
    try {
        const response = await fetch(HTTPURL + 'api/student/get-courses-by-grade',
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('Token')}`
            }
        });
        const responseData = await response.json();
        if (responseData.status) {
            // responseData?.message;
        }
        // else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}