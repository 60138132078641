import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { random_generate_string } from '../../../utils/Utils';
import { get_branch, get_course, get_grade, get_country, get_state, get_term } from '../../../apis/Common';
import { signup_student } from "../../../apis/Signup"
import { validateStep } from './Validation';

const SignUp = () => {
    //state for steps
    const [step, setstep] = useState(1);
    const [grades, setGrades] = useState([]);
    const [terms, setTerms] = useState([]);
    const [course, setCourse] = useState([]);
    const [branch, setBranch] = useState([]);
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    // const [studentType,setStudentType]=useState('')
    const [randomText, setRandomText] = useState('r40e0');
    const [formData, setFormData] = useState({
        course: '',
        branch: '',
        first_name: '',
        last_name: '',
        gender: '',
        date_of_birth: '',
        school: '',
        grade: '',
        term_id: '',
        // p_first_name: '',
        // p_last_name: '',
        student_number: '',
        // mother_mobilenumber: '',
        // father_mobilenumber: '',
        // address: '',
        // city: '',
        // post_code: '',
        // state: '',
        // country: '',
        // p_email: '',
        email: '',
        password: '',
        c_passowrd: '',
        authorizedCode: '',
        captcha: '',
        student_type: '1',
    });

    const [errors, setErrors] = useState({
        course: '',
        branch: '',
        first_name: '',
        last_name: '',
        gender: '',
        dob: '',
        schoolName: '',
        grade: '',
        term_id: '',
        // p_first_name: '',
        // p_last_name: '',
        student_number: null,
        // mother_mobilenumber: '',
        // father_mobilenumber: '',
        // address: '',
        // city: '',
        // post_code: null,
        // state: '',
        // country: '',
        // p_email: '',
        email: '',
        password: '',
        c_passowrd: '',
        authorizedCode: '',
        captcha: '',
        student_type: '1'
    });
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: '',
        });
    };
    // text random
    const text_relaod = () => {
        setRandomText(random_generate_string(5));
    }
    const validateCaptcha = () => {
        return formData.captcha === randomText;
    };

    const previousStep = () => {
        setstep(step - 1);
    }
    // function for going to next step by increasing step state by 1
    const nextStep = () => {
        const currentStep = step;
        const validationErrors = validateStep(currentStep, formData);
        if (Object.values(validationErrors).every((error) => !error)) {
            setstep(step + 1);
        } else {
            setErrors(validationErrors);
        }
    };
    const onSubmit = async (e) => {
        e.preventDefault();
        const res = await signup_student(formData);
        if (res?.status) {
            // return   navigate('/payments') 
            return navigate('/payments');
        }
        nextStep();
    }
    //get grade
    useEffect(() => {
        async function get_data() {
            const grade = await get_grade();
            const course = await get_course();
            const branch = await get_branch();
            const country = await get_country();
            const term = await get_term();
            setTerms(term?.data)
            setGrades(grade?.data);
            setCourse(course?.data);
            setBranch(branch?.data);
            setCountry(country?.data);
        }
        get_data();
    }, []);

    //get state
    useEffect(() => {
        async function get_data() {
            const state = await get_state();
            setState(state?.data);
        }
        get_data();
    }, [localStorage.getItem('countryId')]);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 8);

    const maxDateStr = maxDate.toISOString().split('T')[0];
    return (
        <div className="row">
            <div className="col-12">
                <div className="member_title">
                    <h2>Join Membership</h2>
                    <h4>Returning Student? <Link to="/login">login here</Link></h4>
                </div>

                <div className="tab_out">
                    <nav>
                        <div className="nav nav-tabs join_tab" id="nav-tab" role="tablist">
                            <button onClick={() => setstep(1)} className={`nav-link ${step === 1 ? 'active' : ''}`} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Basic information</button>
                            <button onClick={() => setstep(2)} className={`nav-link ${step === 2 ? 'active' : ''}`} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Students details</button>
                            {/* <button onClick={() => setstep(3)} className={`nav-link ${step === 3 ? 'active' : ''}`} id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Parents details</button> */}
                            <button onClick={() => setstep(3)} className={`nav-link ${step === 3 ? 'active' : ''}`} id="nav-contactn-tab" data-bs-toggle="tab" data-bs-target="nav-contactn" type="button" role="tab" aria-controls="nav-contactn" aria-selected="false">Choose User Name & Password</button>
                        </div>
                    </nav>
                    <form onSubmit={(e) => onSubmit(e)}>
                        {/* javascript switch case to show different form in each step */}
                        <div className="tab_out_cont">
                            <div className="tab-content" id="nav-tabContent">
                                {(() => {
                                    switch (step) {
                                        case 1:
                                            return (
                                                <div className={`tab-pane fade ${step === 1 ? 'show active' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                    <div className="info_cont">
                                                        <h4>Students Information</h4>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">Course</label>
                                                                <select className="form-select" name="course" value={formData.course} onChange={(e) => handleChange(e)} >
                                                                    <option value="">Choose Course</option>
                                                                    {course?.map((elem) => {
                                                                        return (<option value={elem.id}>{elem.name}</option>)
                                                                    })}
                                                                </select>
                                                                {errors.course && <p className="error">{errors.course}</p>}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M11.9951 16.6766V14.1396" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M18.19 5.33008C19.88 5.33008 21.24 6.70008 21.24 8.39008V11.8301C18.78 13.2701 15.53 14.1401 11.99 14.1401C8.45 14.1401 5.21 13.2701 2.75 11.8301V8.38008C2.75 6.69008 4.12 5.33008 5.81 5.33008H18.19Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M15.4951 5.32576V4.95976C15.4951 3.73976 14.5051 2.74976 13.2851 2.74976H10.7051C9.48512 2.74976 8.49512 3.73976 8.49512 4.95976V5.32576"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M2.77441 15.4829L2.96341 17.9919C3.09141 19.6829 4.50041 20.9899 6.19541 20.9899H17.7944C19.4894 20.9899 20.8984 19.6829 21.0264 17.9919L21.2154 15.4829"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">Branch</label>
                                                                <select className="form-select" name="branch" value={formData.branch} onChange={(e) => handleChange(e)}>
                                                                    <option value=''>Choose Branch</option>
                                                                    {branch && branch.map((elem) => {
                                                                        return (
                                                                            <option value={elem.id}>{elem.branch_name}</option>)
                                                                    })}
                                                                </select>
                                                                {errors.branch && <p className="error">{errors.branch}</p>}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="next_btn">
                                                            <button type="button" onClick={() => nextStep()}>NEXT</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                            break;
                                        case 2:
                                            return (
                                                <div className={`tab-pane fade ${step === 2 ? 'show active' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                            <div className="info_cont">
                                                                <h4>Students Information</h4>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">First Name</label>
                                                                        <input type="text" name="first_name" value={formData.first_name}
                                                                            onChange={(e) => handleChange(e)}
                                                                            className="form-control" id="exampleFormControlInput1" placeholder="First Name" />
                                                                        {errors.first_name && <p className="error">{errors.first_name}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.42857"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Last Name</label>
                                                                        <input type="text" className="form-control"
                                                                            name="last_name" onChange={(e) => handleChange(e)}
                                                                            value={formData.last_name} id="exampleFormControlInput1" placeholder="Last Name" />
                                                                        {errors.last_name && <p className="error">{errors.last_name}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.42857"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Gender</label>
                                                                        <select className="form-select" name="gender" onChange={(e) => handleChange(e)}
                                                                            value={formData.gender}>
                                                                            <option value="">Choose Gender</option>
                                                                            <option value="1">Male</option>
                                                                            <option value="2">Female</option>
                                                                            <option value="3">Other</option>
                                                                        </select>
                                                                        {errors.gender && <p className="error">{errors.gender}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.42857"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Date of Birth</label>
                                                                        <input type="date" className="form-control"
                                                                            name="date_of_birth" onChange={(e) => handleChange(e)}
                                                                            value={formData.date_of_birth} id="exampleFormControlInput1" placeholder="Date of birth" max={maxDateStr} />
                                                                        {errors.date_of_birth && <p className="error">{errors.date_of_birth}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3.09277 9.40421H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M7.55769 13.3097H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M7.55769 17.1962H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M16.0438 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M7.96564 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">School Name</label>
                                                                        <input type="text" className="form-control"
                                                                            name="school" onChange={(e) => handleChange(e)}
                                                                            value={formData.school} id="exampleFormControlInput1" placeholder="School Name" />
                                                                        {errors.school && <p className="error">{errors.school}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Grade</label>
                                                                        <select className="form-select" name="grade" onChange={(e) => handleChange(e)}
                                                                            value={formData.grade} >
                                                                            <option value='grade'>Choose Grade</option>
                                                                            {grades && grades.map((elem) => {
                                                                                return (
                                                                                    <option value={elem.id}>{elem.name}</option>)
                                                                            })}
                                                                        </select>
                                                                        {errors.grade && <p className="error">{errors.grade}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3.09277 9.40421H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M7.55769 13.3097H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M7.55769 17.1962H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M16.0438 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M7.96564 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Term</label>
                                                                        <select className="form-select" name="term_id" onChange={(e) => handleChange(e)}
                                                                            value={formData.term_id} >
                                                                            <option value='term'>Choose Term</option>
                                                                            {terms && terms.map((elem) => {
                                                                                return (
                                                                                    <option value={elem.id}>{elem.name}</option>)
                                                                            })}
                                                                        </select>
                                                                        {errors.term_id && <p className="error">{errors.term_id}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3.09277 9.40421H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M7.55769 13.3097H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M7.55769 17.1962H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M16.0438 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M7.96564 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Type</label><br />
                                                                        <div className='container'>
                                                                            <div className='row'>
                                                                                <div className='col-md-6'>
                                                                                    <input
                                                                                        type="radio"
                                                                                        value='0'
                                                                                        name='student_type'
                                                                                        onChange={(e) => {handleChange(e);setStudentType('0')}}
                                                                                    /> <span className="modal-title">Free</span>
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <input
                                                                                        type="radio"
                                                                                        value='1'
                                                                                        name='student_type'
                                                                                        onChange={(e) =>{ handleChange(e);setStudentType('1')}}

                                                                                    /> <span className="modal-title">Enroll</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {errors.student_type && <p className="error">{errors.student_type}</p>}
                                                                    </div>
                                                                </div> */}
                                                                <div className="next_btn ">
                                                                    <a className='prev_btn' href="javascript:;" onClick={() => previousStep()}>PREVIOUS</a>
                                                                    <a href="javascript:;" onClick={() => nextStep()}>NEXT</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );

                                        // case 3:
                                        //     return (
                                        //         <div className={`tab-pane fade ${step === 3 ? 'show active' : ''}`} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        //             <div className="tab-content" id="nav-tabContent">
                                        //                 <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        //                     <div className="info_cont">
                                        //                         <h4>Students Information</h4>
                                        //                     </div>
                                        //                     <div className="row">
                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">Parent's First Name<span className="star_symb">*</span></label>
                                        //                                 <input type="text" className="form-control"
                                        //                                     name="p_first_name" onChange={(e) => handleChange(e)}
                                        //                                     value={formData.p_first_name} id="exampleFormControlInput1" placeholder="First Name" />
                                        //                                 {errors.p_first_name && <p className="error">{errors.p_first_name}</p>}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.42857"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>
                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">Parent's Last Name<span className="star_symb">*</span></label>
                                        //                                 <input type="text" className="form-control"
                                        //                                     name="p_last_name" onChange={(e) => handleChange(e)}
                                        //                                     value={formData.p_last_name}
                                        //                                     id="exampleFormControlInput1" placeholder="Last Name" />
                                        //                                 {errors.p_last_name && <p className="error">{errors.p_last_name}</p>}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.42857"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>
                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">Telephone</label>
                                        //                                 <input onKeyPress={(event) => {
                                        //                                     if (!/[0-9]/.test(event.key)) {
                                        //                                         event.preventDefault();
                                        //                                     }
                                        //                                 }} className="form-control"
                                        //                                     name="student_number" onChange={(e) => handleChange(e)}
                                        //                                     value={formData.student_number}
                                        //                                     id="exampleFormControlInput1" placeholder="Telephone No." />
                                        //                                 {/* {errors.telephone && <p className="error">{errors.telephone}</p>} */}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>
                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">Mother's Mobile Phone<span className="star_symb">*</span></label>
                                        //                                 <input onKeyPress={(event) => {
                                        //                                     if (!/[0-9]/.test(event.key)) {
                                        //                                         event.preventDefault();
                                        //                                     }
                                        //                                 }} className="form-control"
                                        //                                     name="mother_mobilenumber" onChange={(e) => handleChange(e)}
                                        //                                     value={formData.mother_mobilenumber}
                                        //                                     id="exampleFormControlInput1" placeholder="Mobile No." />
                                        //                                 {errors.mother_mobilenumber && <p className="error">{errors.mother_mobilenumber}</p>}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>
                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">Father's Mobile Phone<span className="star_symb">*</span></label>
                                        //                                 <input onKeyPress={(event) => {
                                        //                                     if (!/[0-9]/.test(event.key)) {
                                        //                                         event.preventDefault();
                                        //                                     }
                                        //                                 }} className="form-control"
                                        //                                     name="father_mobilenumber" onChange={(e) => handleChange(e)}
                                        //                                     value={formData.father_mobilenumber}
                                        //                                     id="exampleFormControlInput1" placeholder="Mobile No." />
                                        //                                 {errors.father_mobilenumber && <p className="error">{errors.father_mobilenumber}</p>}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>
                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">Address<span className="star_symb">*</span></label>
                                        //                                 <input type="text" className="form-control"
                                        //                                     name="address" onChange={(e) => handleChange(e)}
                                        //                                     value={formData.address}
                                        //                                     id="exampleFormControlInput1" placeholder="Address" />
                                        //                                 {errors.address && <p className="error">{errors.address}</p>}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>
                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">City/Suburb<span className="star_symb">*</span></label>
                                        //                                 <input type="text" className="form-control"
                                        //                                     name="city" onChange={(e) => handleChange(e)}
                                        //                                     value={formData.city}
                                        //                                     id="exampleFormControlInput1" placeholder="City" />
                                        //                                 {errors.city && <p className="error">{errors.city}</p>}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>
                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">Post Code</label>
                                        //                                 <input onKeyPress={(event) => {
                                        //                                     if (!/[0-9]/.test(event.key)) {
                                        //                                         event.preventDefault();
                                        //                                     }
                                        //                                 }} className="form-control"
                                        //                                     name="post_code" onChange={(e) => handleChange(e)}
                                        //                                     value={formData.post_code}
                                        //                                     id="exampleFormControlInput1" placeholder="Post Code" />
                                        //                                 {/* {errors.postCode && <p className="error">{errors.postCode}</p>} */}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>
                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">Country<span className="star_symb">*</span></label>
                                        //                                 <select className="form-select" name="country" onChange={(e) => {
                                        //                                     handleChange(e);

                                        //                                     localStorage.setItem('countryId', e.target.value)
                                        //                                 }}
                                        //                                     value={formData.country}
                                        //                                 >
                                        //                                     <option value="">Country</option>
                                        //                                     {country && country?.map((elem) => {
                                        //                                         return (
                                        //                                             <option value={elem.id}>{elem.name}</option>)
                                        //                                     })}
                                        //                                 </select>
                                        //                                 {errors.country && <p className="error">{errors.country}</p>}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>
                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">State<span className="star_symb">*</span></label>
                                        //                                 <select className="form-select" name="state" onChange={(e) => handleChange(e)}
                                        //                                     value={formData.state}>
                                        //                                     <option value="">State</option>
                                        //                                     {state && state?.map((elem) => {
                                        //                                         return (
                                        //                                             <>
                                        //                                                 <option value={elem.id}> {elem.name}</option>
                                        //                                                 <option value='1'>australia1</option>
                                        //                                                 <option value='2'>australia2</option>
                                        //                                                 <option value='3'>australia3</option>
                                        //                                                 <option value='4'>australia4</option>
                                        //                                             </>

                                        //                                             )
                                        //                                     })}
                                        //                                 </select>
                                        //                                 {errors.state && <p className="error">{errors.state}</p>}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>

                                        //                         <div className="col-lg-3">
                                        //                             <div className="mb-3 info_field">
                                        //                                 <label for="exampleFormControlInput1" className="form-label">Parent’s Email<span className="star_symb">*</span></label>
                                        //                                 <input type="text" className="form-control"
                                        //                                     name="p_email" onChange={(e) => handleChange(e)}
                                        //                                     value={formData.p_email}
                                        //                                     id="exampleFormControlInput1" placeholder="email" />
                                        //                                 {errors.p_email && <p className="error">{errors.p_email}</p>}
                                        //                                 <div className="work_icon">
                                        //                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //                                         <path
                                        //                                             d="M17.9028 8.85107L13.4596 12.4641C12.6201 13.1301 11.4389 13.1301 10.5994 12.4641L6.11865 8.85107"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                         <path
                                        //                                             fill-rule="evenodd"
                                        //                                             clip-rule="evenodd"
                                        //                                             d="M16.9089 21C19.9502 21.0084 22 18.5095 22 15.4384V8.57001C22 5.49883 19.9502 3 16.9089 3H7.09114C4.04979 3 2 5.49883 2 8.57001V15.4384C2 18.5095 4.04979 21.0084 7.09114 21H16.9089Z"
                                        //                                             stroke="#2D2926"
                                        //                                             stroke-width="1.5"
                                        //                                             stroke-linecap="round"
                                        //                                             stroke-linejoin="round"
                                        //                                         />
                                        //                                     </svg>
                                        //                                 </div>
                                        //                             </div>
                                        //                         </div>
                                        //                         <div className="next_btn">
                                        //                             <a className='prev_btn' href="javascript:;" onClick={() => previousStep()}>PREVIOUS</a>
                                        //                             <a href="javascript:;" onClick={() => nextStep()}>NEXT</a>
                                        //                         </div>
                                        //                     </div>
                                        //                 </div>
                                        //             </div>
                                        //         </div>
                                        //     );

                                        case 3:
                                            return (
                                                <div className={`tab-pane fade ${step === 3 ? 'show active' : ''}`} id="nav-contactn" role="tabpanel" aria-labelledby="nav-contactn-tab">
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                            <div className="info_cont">
                                                                <h4>Students Information</h4>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">User Name(Id)</label>
                                                                        <input type="text" className="form-control"
                                                                            name="email" onChange={(e) => handleChange(e)}
                                                                            value={formData.email}
                                                                            id="exampleFormControlInput1" placeholder="User ID" />
                                                                        {/* {errors.email && <p className="error">{errors.email}</p>} */}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.42857"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Password</label>
                                                                        <input type="password" className="form-control"
                                                                            name="password" onChange={(e) => handleChange(e)}
                                                                            value={formData.password}
                                                                            id="exampleFormControlInput1" placeholder="Password" />
                                                                        {errors.password && <p className="error">{errors.password}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M16.4232 9.4478V7.3008C16.4232 4.7878 14.3852 2.7498 11.8722 2.7498C9.35925 2.7388 7.31325 4.7668 7.30225 7.2808V7.3008V9.4478"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M15.683 21.2497H8.042C5.948 21.2497 4.25 19.5527 4.25 17.4577V13.1687C4.25 11.0737 5.948 9.37671 8.042 9.37671H15.683C17.777 9.37671 19.475 11.0737 19.475 13.1687V17.4577C19.475 19.5527 17.777 21.2497 15.683 21.2497Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path d="M11.8628 14.2026V16.4236" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Confirm Password</label>
                                                                        <input type="password" className="form-control"
                                                                            name="c_passowrd" onChange={(e) => handleChange(e)}
                                                                            value={formData.c_passowrd}
                                                                            id="exampleFormControlInput1" placeholder="Confirm Password" />
                                                                        {errors.c_passowrd && <p className="error">{errors.c_passowrd}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M16.4232 9.4478V7.3008C16.4232 4.7878 14.3852 2.7498 11.8722 2.7498C9.35925 2.7388 7.31325 4.7668 7.30225 7.2808V7.3008V9.4478"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M15.683 21.2497H8.042C5.948 21.2497 4.25 19.5527 4.25 17.4577V13.1687C4.25 11.0737 5.948 9.37671 8.042 9.37671H15.683C17.777 9.37671 19.475 11.0737 19.475 13.1687V17.4577C19.475 19.5527 17.777 21.2497 15.683 21.2497Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path d="M11.8628 14.2026V16.4236" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">The authorization code</label>
                                                                        <input type="text" className="form-control"
                                                                            name="authorizedCode" onChange={(e) => handleChange(e)}
                                                                            value={formData.authorizedCode}
                                                                            id="exampleFormControlInput1" placeholder="Enter Code" />
                                                                        {errors.authorizedCode && <p className="error">{errors.authorizedCode}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M16.334 2.75H7.665C4.644 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.635 21.25 7.665 21.25H16.333C19.364 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.364 2.75 16.334 2.75Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M10.6894 11.9999C10.6894 13.0229 9.86035 13.8519 8.83735 13.8519C7.81435 13.8519 6.98535 13.0229 6.98535 11.9999C6.98535 10.9769 7.81435 10.1479 8.83735 10.1479H8.84035C9.86135 10.1489 10.6894 10.9779 10.6894 11.9999Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path d="M10.6924 12H17.0104V13.852" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M14.1816 13.852V12" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="relod_cont">
                                                                        <input type="text" name="captcha" onChange={(e) => handleChange(e)}
                                                                            value={formData.captcha} />
                                                                        {errors.captcha && <p className="error">{errors.captcha}</p>}
                                                                        {!validateCaptcha() && <p className="error">Captcha does not match</p>}
                                                                        <h2>{randomText}</h2>
                                                                        <h4 onClick={() => text_relaod()}>Reload</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="next_btn member_sub_btn">
                                                                    <a className='prev_btn' href="javascript:;" onClick={() => previousStep()}>PREVIOUS</a>
                                                                    <button type="submit">Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        default:
                                            return null
                                    }
                                })()
                                }
                            </div>
                        </div>
                    </form>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="member_cont">
                            <h4>Hybrid Learning</h4>
                            <p>Hybrid learning implements synchronous lessons taught simultaneously in-person and online.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="member_cont">
                            <h4>Top Materials</h4>
                            <p>All materials are designed and prepared by a team of highly qualified and experienced educators.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="member_cont">
                            <h4>Best Teachers</h4>
                            <p>Qualified and experienced teachers ensure students enjoy their studies & achieve their ambitions.</p>
                        </div>
                    </div>
                </div>
                <div className="group_img">
                    <div className="group_inner_img">
                        <img className="w-100" src="./images/csgroup.png" alt="csgroup" />
                    </div>
                    <div className="group_inner_img">
                        <img className="w-100" src="./images/testbank.png" alt="csgroup" />
                    </div>
                    <div className="group_inner_img">
                        <img className="w-100" src="./images/csinstitute.png" alt="csgroup" />
                    </div>
                    <div className="group_inner_img">
                        <img className="w-100" src="./images/csschool.png" alt="csgroup" />
                    </div>
                    <div className="group_inner_img">
                        <img className="w-100" src="./images/csfranchise.png" alt="csgroup" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp


