import { HTTPURL } from '../constants/Matcher';



export async function get_website_data() {
    try {
        const response = await fetch(HTTPURL + 'api/get-website-data');
        const responseData = await response.json();
        if (responseData.status) {
            // responseData?.message;
        }
        // else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}