export const validateStep = (step, data) => 
{
    const validationErrors = {};
    switch (step) {
        case 1:
            if (!data.branch) {
                validationErrors.branch = 'Select an option.';
            }
            if (!data.course) {
                validationErrors.course = 'Select an option.';
            }
            
            break;
        case 2:
            if (!data.first_name) {
                validationErrors.first_name = 'Enter your First Name.';
            }
            if (!data.last_name) {
                validationErrors.last_name = 'Enter your Last Name.';
            }
            if (!data.gender) {
                validationErrors.gender = 'Select an option.';
            }
            if (!data.date_of_birth) {
                validationErrors.date_of_birth = 'Enter your DOB.';
            }
            if (!data.school) {
                validationErrors.school = 'Enter your School Name.';
            }
            if (!data.grade) {
                validationErrors.grade = 'Select an option.';
            }
            if (!data.term_id) {
                validationErrors.term_id = 'Select an option.';
            }
             if (!data.student_type) {
                validationErrors.student_type = 'Select an type.';
            }
            break;
        // case 3:
        //     if (!data.p_first_name) {
        //         validationErrors.p_first_name = 'Please enter your first name.';
        //     }
        //     if (!data.p_last_name) {
        //         validationErrors.p_last_name = 'Please enter your last name.';
        //     }
        //     // if (!data.telephone) {
        //     //     validationErrors.telephone = 'Telephone number is required.';
        //     // } else if (!/^\d{10}$/.test(data.telephone)) {
        //     //     validationErrors.telephone = 'Telephone number must be 10 digits.';
        //     // }
        //     if (!data.mother_mobilenumber) {
        //         validationErrors.mother_mobilenumber = 'Mobile number is required.';
        //     } else if (!/^\d{10}$/.test(data.mother_mobilenumber)) {
        //         validationErrors.mother_mobilenumber = 'Mobile number must be 10 digits.';
        //     }
        //     if (!data.father_mobilenumber) {
        //         validationErrors.father_mobilenumber = 'Mobile number is required.';
        //     } else if (!/^\d{10}$/.test(data.father_mobilenumber)) {
        //         validationErrors.father_mobilenumber = 'Mobile number must be 10 digits.';
        //     }
        //     if (!data.address) {
        //         validationErrors.address = 'Please enter your address.';
        //     }
        //     if (!data.city) {
        //         validationErrors.city = 'Please enter your city.';
        //     }
        //     // if (!data.postCode) {
        //     //     validationErrors.postCode = 'Post code is required.';
        //     // } else if (!/^\d{6}$/.test(data.postCode)) {
        //     //     validationErrors.postCode = 'Post code must be 6 digits.';
        //     // }
        //     if (!data.state) {
        //         validationErrors.state = 'Please select an option.';
        //     }
        //     if (!data.country) {
        //         validationErrors.country = 'Please select an option.';
        //     }
        //     if (!data.p_email) {
        //         validationErrors.p_email = 'Email is required.';
        //     } else if (!/^\S+@\S+\.\S+$/.test(data.p_email)) {
        //         validationErrors.p_email = 'Email is not valid.';
        //     }
        //     break;  
        case 3:
            if (!data.email) {
                validationErrors.email = 'Enter your userId.';
            }
            if (!data.password) {
                validationErrors.password = 'Password is required.';
            } else if (data.password.length < 6) {
                validationErrors.password =
                  'Password must be at least 6 characters long.';
            }
            if (!data.c_passowrd) {
                validationErrors.c_passowrd = 'Confirm Password is required.';
            } else if (data.password !== data.c_passowrd) {
                validationErrors.c_passowrd = 'Passwords do not match.';
            }
            if (!data.authorizedCode) {
                validationErrors.authorizedCode = 'Please enter your authorized code.';
            }
            if (!data.captcha) {
                validationErrors.captcha = 'Enter captcha.';
            }
            break;  
        default:
            break;
    }
    return validationErrors;
}